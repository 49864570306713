










































import { Component, Vue } from "nuxt-property-decorator";

@Component<MFooterColumns>({})
export default class MFooterColumns extends Vue {}
