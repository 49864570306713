import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f0343b5c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e0301f60 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _0dec6555 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _3ee9196e = () => interopDefault(import('../pages/branding/index.vue' /* webpackChunkName: "pages/branding/index" */))
const _5e1c9e06 = () => interopDefault(import('../pages/brief/index.vue' /* webpackChunkName: "pages/brief/index" */))
const _d0a9979e = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _083089ba = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _54848494 = () => interopDefault(import('../pages/e-commerce/index.vue' /* webpackChunkName: "pages/e-commerce/index" */))
const _3ddb7b77 = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _195df6c3 = () => interopDefault(import('../pages/mobile-development/index.vue' /* webpackChunkName: "pages/mobile-development/index" */))
const _1e6df04b = () => interopDefault(import('../pages/portfolio/index.vue' /* webpackChunkName: "pages/portfolio/index" */))
const _73c1e6ec = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _6599ee76 = () => interopDefault(import('../pages/web-development/index.vue' /* webpackChunkName: "pages/web-development/index" */))
const _7c4fd4c0 = () => interopDefault(import('../pages/ecommerce.vue' /* webpackChunkName: "pages/ecommerce" */))
const _f93f68d8 = () => interopDefault(import('../pages/webistes.vue' /* webpackChunkName: "pages/webistes" */))
const _14b7cf33 = () => interopDefault(import('../pages/brief/error.vue' /* webpackChunkName: "pages/brief/error" */))
const _02dd2224 = () => interopDefault(import('../pages/brief/success.vue' /* webpackChunkName: "pages/brief/success" */))
const _1d2cb24d = () => interopDefault(import('../pages/services/audit-ecommerce.vue' /* webpackChunkName: "pages/services/audit-ecommerce" */))
const _6dbb9dbb = () => interopDefault(import('../pages/services/audit-websites.vue' /* webpackChunkName: "pages/services/audit-websites" */))
const _0ba2d60c = () => interopDefault(import('../pages/services/branding.vue' /* webpackChunkName: "pages/services/branding" */))
const _057152d4 = () => interopDefault(import('../pages/services/dedicated-software.vue' /* webpackChunkName: "pages/services/dedicated-software" */))
const _1d4dff8e = () => interopDefault(import('../pages/services/design-workshops.vue' /* webpackChunkName: "pages/services/design-workshops" */))
const _3d476ae5 = () => interopDefault(import('../pages/services/marketing.vue' /* webpackChunkName: "pages/services/marketing" */))
const _684f31e1 = () => interopDefault(import('../pages/services/mobile-development.vue' /* webpackChunkName: "pages/services/mobile-development" */))
const _508e8336 = () => interopDefault(import('../pages/services/outsourcing.vue' /* webpackChunkName: "pages/services/outsourcing" */))
const _118a182d = () => interopDefault(import('../pages/services/product-design.vue' /* webpackChunkName: "pages/services/product-design" */))
const _988c09a4 = () => interopDefault(import('../pages/services/qa-and-testing.vue' /* webpackChunkName: "pages/services/qa-and-testing" */))
const _7e65e272 = () => interopDefault(import('../pages/services/seo-sem-sxo.vue' /* webpackChunkName: "pages/services/seo-sem-sxo" */))
const _4b44e0d6 = () => interopDefault(import('../pages/services/social-media.vue' /* webpackChunkName: "pages/services/social-media" */))
const _b3b13680 = () => interopDefault(import('../pages/services/ui-ux-design.vue' /* webpackChunkName: "pages/services/ui-ux-design" */))
const _3bb695c1 = () => interopDefault(import('../pages/services/web-development.vue' /* webpackChunkName: "pages/services/web-development" */))
const _eab4211e = () => interopDefault(import('../pages/software/customer-relationship-management.vue' /* webpackChunkName: "pages/software/customer-relationship-management" */))
const _3b1420aa = () => interopDefault(import('../pages/software/e-commerce.vue' /* webpackChunkName: "pages/software/e-commerce" */))
const _428ea25a = () => interopDefault(import('../pages/software/enterprise-application-integration.vue' /* webpackChunkName: "pages/software/enterprise-application-integration" */))
const _86639e90 = () => interopDefault(import('../pages/software/enterprise-resource-planning.vue' /* webpackChunkName: "pages/software/enterprise-resource-planning" */))
const _721fd712 = () => interopDefault(import('../pages/software/financial-and-accounting.vue' /* webpackChunkName: "pages/software/financial-and-accounting" */))
const _0c35c40d = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _d416da2e = () => interopDefault(import('../pages/career/_slug.vue' /* webpackChunkName: "pages/career/_slug" */))
const _1cb74f03 = () => interopDefault(import('../pages/portfolio/_slug.vue' /* webpackChunkName: "pages/portfolio/_slug" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de/",
    component: _f0343b5c,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/en/",
    component: _f0343b5c,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/no/",
    component: _f0343b5c,
    pathToRegexpOptions: {"strict":true},
    name: "index___no"
  }, {
    path: "/pl/",
    component: _f0343b5c,
    pathToRegexpOptions: {"strict":true},
    name: "index___pl"
  }, {
    path: "/de/about/",
    component: _e0301f60,
    pathToRegexpOptions: {"strict":true},
    name: "about___de"
  }, {
    path: "/de/blog/",
    component: _0dec6555,
    pathToRegexpOptions: {"strict":true},
    name: "blog___de"
  }, {
    path: "/de/branding/",
    component: _3ee9196e,
    pathToRegexpOptions: {"strict":true},
    name: "branding___de"
  }, {
    path: "/de/brief/",
    component: _5e1c9e06,
    pathToRegexpOptions: {"strict":true},
    name: "brief___de"
  }, {
    path: "/de/career/",
    component: _d0a9979e,
    pathToRegexpOptions: {"strict":true},
    name: "career___de"
  }, {
    path: "/de/contact/",
    component: _083089ba,
    pathToRegexpOptions: {"strict":true},
    name: "contact___de"
  }, {
    path: "/de/e-commerce/",
    component: _54848494,
    pathToRegexpOptions: {"strict":true},
    name: "e-commerce___de"
  }, {
    path: "/de/impressum/",
    component: _3ddb7b77,
    pathToRegexpOptions: {"strict":true},
    name: "impressum___de"
  }, {
    path: "/de/mobile-development/",
    component: _195df6c3,
    pathToRegexpOptions: {"strict":true},
    name: "mobile-development___de"
  }, {
    path: "/de/portfolio/",
    component: _1e6df04b,
    pathToRegexpOptions: {"strict":true},
    name: "portfolio___de"
  }, {
    path: "/de/privacy-policy/",
    component: _73c1e6ec,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___de"
  }, {
    path: "/de/web-development/",
    component: _6599ee76,
    pathToRegexpOptions: {"strict":true},
    name: "web-development___de"
  }, {
    path: "/en/about/",
    component: _e0301f60,
    pathToRegexpOptions: {"strict":true},
    name: "about___en"
  }, {
    path: "/en/blog/",
    component: _0dec6555,
    pathToRegexpOptions: {"strict":true},
    name: "blog___en"
  }, {
    path: "/en/branding/",
    component: _3ee9196e,
    pathToRegexpOptions: {"strict":true},
    name: "branding___en"
  }, {
    path: "/en/career/",
    component: _d0a9979e,
    pathToRegexpOptions: {"strict":true},
    name: "career___en"
  }, {
    path: "/en/contact/",
    component: _083089ba,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en"
  }, {
    path: "/en/e-commerce/",
    component: _54848494,
    pathToRegexpOptions: {"strict":true},
    name: "e-commerce___en"
  }, {
    path: "/en/mobile-development/",
    component: _195df6c3,
    pathToRegexpOptions: {"strict":true},
    name: "mobile-development___en"
  }, {
    path: "/en/portfolio/",
    component: _1e6df04b,
    pathToRegexpOptions: {"strict":true},
    name: "portfolio___en"
  }, {
    path: "/en/privacy-policy/",
    component: _73c1e6ec,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en"
  }, {
    path: "/en/project-estimation/",
    component: _5e1c9e06,
    pathToRegexpOptions: {"strict":true},
    name: "brief___en"
  }, {
    path: "/en/web-development/",
    component: _6599ee76,
    pathToRegexpOptions: {"strict":true},
    name: "web-development___en"
  }, {
    path: "/no/about/",
    component: _e0301f60,
    pathToRegexpOptions: {"strict":true},
    name: "about___no"
  }, {
    path: "/no/blog/",
    component: _0dec6555,
    pathToRegexpOptions: {"strict":true},
    name: "blog___no"
  }, {
    path: "/no/branding/",
    component: _3ee9196e,
    pathToRegexpOptions: {"strict":true},
    name: "branding___no"
  }, {
    path: "/no/brief/",
    component: _5e1c9e06,
    pathToRegexpOptions: {"strict":true},
    name: "brief___no"
  }, {
    path: "/no/career/",
    component: _d0a9979e,
    pathToRegexpOptions: {"strict":true},
    name: "career___no"
  }, {
    path: "/no/contact/",
    component: _083089ba,
    pathToRegexpOptions: {"strict":true},
    name: "contact___no"
  }, {
    path: "/no/e-commerce/",
    component: _54848494,
    pathToRegexpOptions: {"strict":true},
    name: "e-commerce___no"
  }, {
    path: "/no/mobile-development/",
    component: _195df6c3,
    pathToRegexpOptions: {"strict":true},
    name: "mobile-development___no"
  }, {
    path: "/no/portfolio/",
    component: _1e6df04b,
    pathToRegexpOptions: {"strict":true},
    name: "portfolio___no"
  }, {
    path: "/no/privacy-policy/",
    component: _73c1e6ec,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___no"
  }, {
    path: "/no/web-development/",
    component: _6599ee76,
    pathToRegexpOptions: {"strict":true},
    name: "web-development___no"
  }, {
    path: "/pl/blog/",
    component: _0dec6555,
    pathToRegexpOptions: {"strict":true},
    name: "blog___pl"
  }, {
    path: "/pl/branding/",
    component: _3ee9196e,
    pathToRegexpOptions: {"strict":true},
    name: "branding___pl"
  }, {
    path: "/pl/e-commerce/",
    component: _54848494,
    pathToRegexpOptions: {"strict":true},
    name: "e-commerce___pl"
  }, {
    path: "/pl/kariera/",
    component: _d0a9979e,
    pathToRegexpOptions: {"strict":true},
    name: "career___pl"
  }, {
    path: "/pl/kontakt/",
    component: _083089ba,
    pathToRegexpOptions: {"strict":true},
    name: "contact___pl"
  }, {
    path: "/pl/mobile-development/",
    component: _195df6c3,
    pathToRegexpOptions: {"strict":true},
    name: "mobile-development___pl"
  }, {
    path: "/pl/o-nas/",
    component: _e0301f60,
    pathToRegexpOptions: {"strict":true},
    name: "about___pl"
  }, {
    path: "/pl/polityka-prywatnosci/",
    component: _73c1e6ec,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___pl"
  }, {
    path: "/pl/portfolio/",
    component: _1e6df04b,
    pathToRegexpOptions: {"strict":true},
    name: "portfolio___pl"
  }, {
    path: "/pl/sklepy-internetowe-czestochowa/",
    component: _7c4fd4c0,
    pathToRegexpOptions: {"strict":true},
    name: "ecommerce___pl"
  }, {
    path: "/pl/strony-internetowe-czestochowa/",
    component: _f93f68d8,
    pathToRegexpOptions: {"strict":true},
    name: "webistes___pl"
  }, {
    path: "/pl/web-development/",
    component: _6599ee76,
    pathToRegexpOptions: {"strict":true},
    name: "web-development___pl"
  }, {
    path: "/pl/wycena-projektu/",
    component: _5e1c9e06,
    pathToRegexpOptions: {"strict":true},
    name: "brief___pl"
  }, {
    path: "/de/brief/error/",
    component: _14b7cf33,
    pathToRegexpOptions: {"strict":true},
    name: "brief-error___de"
  }, {
    path: "/de/brief/success/",
    component: _02dd2224,
    pathToRegexpOptions: {"strict":true},
    name: "brief-success___de"
  }, {
    path: "/de/services/audit-ecommerce/",
    component: _1d2cb24d,
    pathToRegexpOptions: {"strict":true},
    name: "services-audit-ecommerce___de"
  }, {
    path: "/de/services/audit-websites/",
    component: _6dbb9dbb,
    pathToRegexpOptions: {"strict":true},
    name: "services-audit-websites___de"
  }, {
    path: "/de/services/branding/",
    component: _0ba2d60c,
    pathToRegexpOptions: {"strict":true},
    name: "services-branding___de"
  }, {
    path: "/de/services/dedicated-software/",
    component: _057152d4,
    pathToRegexpOptions: {"strict":true},
    name: "services-dedicated-software___de"
  }, {
    path: "/de/services/design-workshops/",
    component: _1d4dff8e,
    pathToRegexpOptions: {"strict":true},
    name: "services-design-workshops___de"
  }, {
    path: "/de/services/marketing/",
    component: _3d476ae5,
    pathToRegexpOptions: {"strict":true},
    name: "services-marketing___de"
  }, {
    path: "/de/services/mobile-development/",
    component: _684f31e1,
    pathToRegexpOptions: {"strict":true},
    name: "services-mobile-development___de"
  }, {
    path: "/de/services/outsourcing/",
    component: _508e8336,
    pathToRegexpOptions: {"strict":true},
    name: "services-outsourcing___de"
  }, {
    path: "/de/services/product-design/",
    component: _118a182d,
    pathToRegexpOptions: {"strict":true},
    name: "services-product-design___de"
  }, {
    path: "/de/services/qa-and-testing/",
    component: _988c09a4,
    pathToRegexpOptions: {"strict":true},
    name: "services-qa-and-testing___de"
  }, {
    path: "/de/services/seo-sem-sxo/",
    component: _7e65e272,
    pathToRegexpOptions: {"strict":true},
    name: "services-seo-sem-sxo___de"
  }, {
    path: "/de/services/social-media/",
    component: _4b44e0d6,
    pathToRegexpOptions: {"strict":true},
    name: "services-social-media___de"
  }, {
    path: "/de/services/ui-ux-design/",
    component: _b3b13680,
    pathToRegexpOptions: {"strict":true},
    name: "services-ui-ux-design___de"
  }, {
    path: "/de/services/web-development/",
    component: _3bb695c1,
    pathToRegexpOptions: {"strict":true},
    name: "services-web-development___de"
  }, {
    path: "/de/software/customer-relationship-management/",
    component: _eab4211e,
    pathToRegexpOptions: {"strict":true},
    name: "software-customer-relationship-management___de"
  }, {
    path: "/de/software/e-commerce/",
    component: _3b1420aa,
    pathToRegexpOptions: {"strict":true},
    name: "software-e-commerce___de"
  }, {
    path: "/de/software/enterprise-application-integration/",
    component: _428ea25a,
    pathToRegexpOptions: {"strict":true},
    name: "software-enterprise-application-integration___de"
  }, {
    path: "/de/software/enterprise-resource-planning/",
    component: _86639e90,
    pathToRegexpOptions: {"strict":true},
    name: "software-enterprise-resource-planning___de"
  }, {
    path: "/de/software/financial-and-accounting/",
    component: _721fd712,
    pathToRegexpOptions: {"strict":true},
    name: "software-financial-and-accounting___de"
  }, {
    path: "/en/project-estimation/error/",
    component: _14b7cf33,
    pathToRegexpOptions: {"strict":true},
    name: "brief-error___en"
  }, {
    path: "/en/project-estimation/success/",
    component: _02dd2224,
    pathToRegexpOptions: {"strict":true},
    name: "brief-success___en"
  }, {
    path: "/en/services/audit-ecommerce/",
    component: _1d2cb24d,
    pathToRegexpOptions: {"strict":true},
    name: "services-audit-ecommerce___en"
  }, {
    path: "/en/services/audit-websites/",
    component: _6dbb9dbb,
    pathToRegexpOptions: {"strict":true},
    name: "services-audit-websites___en"
  }, {
    path: "/en/services/branding/",
    component: _0ba2d60c,
    pathToRegexpOptions: {"strict":true},
    name: "services-branding___en"
  }, {
    path: "/en/services/dedicated-software/",
    component: _057152d4,
    pathToRegexpOptions: {"strict":true},
    name: "services-dedicated-software___en"
  }, {
    path: "/en/services/design-workshops/",
    component: _1d4dff8e,
    pathToRegexpOptions: {"strict":true},
    name: "services-design-workshops___en"
  }, {
    path: "/en/services/marketing/",
    component: _3d476ae5,
    pathToRegexpOptions: {"strict":true},
    name: "services-marketing___en"
  }, {
    path: "/en/services/mobile-development/",
    component: _684f31e1,
    pathToRegexpOptions: {"strict":true},
    name: "services-mobile-development___en"
  }, {
    path: "/en/services/outsourcing/",
    component: _508e8336,
    pathToRegexpOptions: {"strict":true},
    name: "services-outsourcing___en"
  }, {
    path: "/en/services/product-design/",
    component: _118a182d,
    pathToRegexpOptions: {"strict":true},
    name: "services-product-design___en"
  }, {
    path: "/en/services/quality-assurance-and-testing/",
    component: _988c09a4,
    pathToRegexpOptions: {"strict":true},
    name: "services-qa-and-testing___en"
  }, {
    path: "/en/services/seo-sem-sxo/",
    component: _7e65e272,
    pathToRegexpOptions: {"strict":true},
    name: "services-seo-sem-sxo___en"
  }, {
    path: "/en/services/social-media/",
    component: _4b44e0d6,
    pathToRegexpOptions: {"strict":true},
    name: "services-social-media___en"
  }, {
    path: "/en/services/ui-ux-design/",
    component: _b3b13680,
    pathToRegexpOptions: {"strict":true},
    name: "services-ui-ux-design___en"
  }, {
    path: "/en/services/web-development/",
    component: _3bb695c1,
    pathToRegexpOptions: {"strict":true},
    name: "services-web-development___en"
  }, {
    path: "/en/software/customer-relationship-management/",
    component: _eab4211e,
    pathToRegexpOptions: {"strict":true},
    name: "software-customer-relationship-management___en"
  }, {
    path: "/en/software/e-commerce-systems/",
    component: _3b1420aa,
    pathToRegexpOptions: {"strict":true},
    name: "software-e-commerce___en"
  }, {
    path: "/en/software/enterprise-application-integration/",
    component: _428ea25a,
    pathToRegexpOptions: {"strict":true},
    name: "software-enterprise-application-integration___en"
  }, {
    path: "/en/software/enterprise-resource-planning/",
    component: _86639e90,
    pathToRegexpOptions: {"strict":true},
    name: "software-enterprise-resource-planning___en"
  }, {
    path: "/en/software/financial-and-accounting-systems/",
    component: _721fd712,
    pathToRegexpOptions: {"strict":true},
    name: "software-financial-and-accounting___en"
  }, {
    path: "/no/brief/error/",
    component: _14b7cf33,
    pathToRegexpOptions: {"strict":true},
    name: "brief-error___no"
  }, {
    path: "/no/brief/success/",
    component: _02dd2224,
    pathToRegexpOptions: {"strict":true},
    name: "brief-success___no"
  }, {
    path: "/no/services/audit-ecommerce/",
    component: _1d2cb24d,
    pathToRegexpOptions: {"strict":true},
    name: "services-audit-ecommerce___no"
  }, {
    path: "/no/services/audit-websites/",
    component: _6dbb9dbb,
    pathToRegexpOptions: {"strict":true},
    name: "services-audit-websites___no"
  }, {
    path: "/no/services/branding/",
    component: _0ba2d60c,
    pathToRegexpOptions: {"strict":true},
    name: "services-branding___no"
  }, {
    path: "/no/services/dedicated-software/",
    component: _057152d4,
    pathToRegexpOptions: {"strict":true},
    name: "services-dedicated-software___no"
  }, {
    path: "/no/services/design-workshops/",
    component: _1d4dff8e,
    pathToRegexpOptions: {"strict":true},
    name: "services-design-workshops___no"
  }, {
    path: "/no/services/marketing/",
    component: _3d476ae5,
    pathToRegexpOptions: {"strict":true},
    name: "services-marketing___no"
  }, {
    path: "/no/services/mobile-development/",
    component: _684f31e1,
    pathToRegexpOptions: {"strict":true},
    name: "services-mobile-development___no"
  }, {
    path: "/no/services/outsourcing/",
    component: _508e8336,
    pathToRegexpOptions: {"strict":true},
    name: "services-outsourcing___no"
  }, {
    path: "/no/services/product-design/",
    component: _118a182d,
    pathToRegexpOptions: {"strict":true},
    name: "services-product-design___no"
  }, {
    path: "/no/services/qa-and-testing/",
    component: _988c09a4,
    pathToRegexpOptions: {"strict":true},
    name: "services-qa-and-testing___no"
  }, {
    path: "/no/services/seo-sem-sxo/",
    component: _7e65e272,
    pathToRegexpOptions: {"strict":true},
    name: "services-seo-sem-sxo___no"
  }, {
    path: "/no/services/social-media/",
    component: _4b44e0d6,
    pathToRegexpOptions: {"strict":true},
    name: "services-social-media___no"
  }, {
    path: "/no/services/ui-ux-design/",
    component: _b3b13680,
    pathToRegexpOptions: {"strict":true},
    name: "services-ui-ux-design___no"
  }, {
    path: "/no/services/web-development/",
    component: _3bb695c1,
    pathToRegexpOptions: {"strict":true},
    name: "services-web-development___no"
  }, {
    path: "/no/software/customer-relationship-management/",
    component: _eab4211e,
    pathToRegexpOptions: {"strict":true},
    name: "software-customer-relationship-management___no"
  }, {
    path: "/no/software/e-commerce/",
    component: _3b1420aa,
    pathToRegexpOptions: {"strict":true},
    name: "software-e-commerce___no"
  }, {
    path: "/no/software/enterprise-application-integration/",
    component: _428ea25a,
    pathToRegexpOptions: {"strict":true},
    name: "software-enterprise-application-integration___no"
  }, {
    path: "/no/software/enterprise-resource-planning/",
    component: _86639e90,
    pathToRegexpOptions: {"strict":true},
    name: "software-enterprise-resource-planning___no"
  }, {
    path: "/no/software/financial-and-accounting/",
    component: _721fd712,
    pathToRegexpOptions: {"strict":true},
    name: "software-financial-and-accounting___no"
  }, {
    path: "/pl/oprogramowanie/customer-relationship-management/",
    component: _eab4211e,
    pathToRegexpOptions: {"strict":true},
    name: "software-customer-relationship-management___pl"
  }, {
    path: "/pl/oprogramowanie/enterprise-application-integration/",
    component: _428ea25a,
    pathToRegexpOptions: {"strict":true},
    name: "software-enterprise-application-integration___pl"
  }, {
    path: "/pl/oprogramowanie/enterprise-resource-planning/",
    component: _86639e90,
    pathToRegexpOptions: {"strict":true},
    name: "software-enterprise-resource-planning___pl"
  }, {
    path: "/pl/oprogramowanie/systemy-e-commerce/",
    component: _3b1420aa,
    pathToRegexpOptions: {"strict":true},
    name: "software-e-commerce___pl"
  }, {
    path: "/pl/oprogramowanie/systemy-finansowo-ksiegowe/",
    component: _721fd712,
    pathToRegexpOptions: {"strict":true},
    name: "software-financial-and-accounting___pl"
  }, {
    path: "/pl/uslugi/audyt-sklepow-internetowych/",
    component: _1d2cb24d,
    pathToRegexpOptions: {"strict":true},
    name: "services-audit-ecommerce___pl"
  }, {
    path: "/pl/uslugi/audyt-stron-internetowych/",
    component: _6dbb9dbb,
    pathToRegexpOptions: {"strict":true},
    name: "services-audit-websites___pl"
  }, {
    path: "/pl/uslugi/branding/",
    component: _0ba2d60c,
    pathToRegexpOptions: {"strict":true},
    name: "services-branding___pl"
  }, {
    path: "/pl/uslugi/marketing/",
    component: _3d476ae5,
    pathToRegexpOptions: {"strict":true},
    name: "services-marketing___pl"
  }, {
    path: "/pl/uslugi/oprogramowanie-dedykowane/",
    component: _057152d4,
    pathToRegexpOptions: {"strict":true},
    name: "services-dedicated-software___pl"
  }, {
    path: "/pl/uslugi/outsourcing/",
    component: _508e8336,
    pathToRegexpOptions: {"strict":true},
    name: "services-outsourcing___pl"
  }, {
    path: "/pl/uslugi/product-design/",
    component: _118a182d,
    pathToRegexpOptions: {"strict":true},
    name: "services-product-design___pl"
  }, {
    path: "/pl/uslugi/quality-assurance-i-testowanie/",
    component: _988c09a4,
    pathToRegexpOptions: {"strict":true},
    name: "services-qa-and-testing___pl"
  }, {
    path: "/pl/uslugi/seo-sem-sxo/",
    component: _7e65e272,
    pathToRegexpOptions: {"strict":true},
    name: "services-seo-sem-sxo___pl"
  }, {
    path: "/pl/uslugi/social-media/",
    component: _4b44e0d6,
    pathToRegexpOptions: {"strict":true},
    name: "services-social-media___pl"
  }, {
    path: "/pl/uslugi/tworzenie-aplikacji-mobilnych/",
    component: _684f31e1,
    pathToRegexpOptions: {"strict":true},
    name: "services-mobile-development___pl"
  }, {
    path: "/pl/uslugi/tworzenie-aplikacji-webowych/",
    component: _3bb695c1,
    pathToRegexpOptions: {"strict":true},
    name: "services-web-development___pl"
  }, {
    path: "/pl/uslugi/ui-ux-design/",
    component: _b3b13680,
    pathToRegexpOptions: {"strict":true},
    name: "services-ui-ux-design___pl"
  }, {
    path: "/pl/uslugi/warsztaty-projektowe/",
    component: _1d4dff8e,
    pathToRegexpOptions: {"strict":true},
    name: "services-design-workshops___pl"
  }, {
    path: "/pl/wycena-projektu/error/",
    component: _14b7cf33,
    pathToRegexpOptions: {"strict":true},
    name: "brief-error___pl"
  }, {
    path: "/pl/wycena-projektu/success/",
    component: _02dd2224,
    pathToRegexpOptions: {"strict":true},
    name: "brief-success___pl"
  }, {
    path: "/de/blog/:slug/",
    component: _0c35c40d,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___de"
  }, {
    path: "/de/career/:slug/",
    component: _d416da2e,
    pathToRegexpOptions: {"strict":true},
    name: "career-slug___de"
  }, {
    path: "/de/portfolio/:slug/",
    component: _1cb74f03,
    pathToRegexpOptions: {"strict":true},
    name: "portfolio-slug___de"
  }, {
    path: "/en/blog/:slug/",
    component: _0c35c40d,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___en"
  }, {
    path: "/en/career/:slug/",
    component: _d416da2e,
    pathToRegexpOptions: {"strict":true},
    name: "career-slug___en"
  }, {
    path: "/en/portfolio/:slug/",
    component: _1cb74f03,
    pathToRegexpOptions: {"strict":true},
    name: "portfolio-slug___en"
  }, {
    path: "/no/blog/:slug/",
    component: _0c35c40d,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___no"
  }, {
    path: "/no/career/:slug/",
    component: _d416da2e,
    pathToRegexpOptions: {"strict":true},
    name: "career-slug___no"
  }, {
    path: "/no/portfolio/:slug/",
    component: _1cb74f03,
    pathToRegexpOptions: {"strict":true},
    name: "portfolio-slug___no"
  }, {
    path: "/pl/blog/:slug/",
    component: _0c35c40d,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___pl"
  }, {
    path: "/pl/kariera/:slug/",
    component: _d416da2e,
    pathToRegexpOptions: {"strict":true},
    name: "career-slug___pl"
  }, {
    path: "/pl/portfolio/:slug/",
    component: _1cb74f03,
    pathToRegexpOptions: {"strict":true},
    name: "portfolio-slug___pl"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
