

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: true,
  locales: [{"code":"pl","file":"pl-PL.js","iso":"pl","name":"PL"},{"code":"en","file":"en-US.js","iso":"en-US","name":"EN"},{"code":"no","file":"no-NO.js","iso":"no-NO","name":"NO"},{"code":"de","file":"de-DE.js","iso":"de-DE","name":"DE"}],
  defaultLocale: "pl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/var/www/imoli.app/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "https://imoli.dev",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {"impressum":{"en":false,"pl":false,"no":false},"sklepy-czestochowa":{"en":false,"de":false,"no":false},"strony-czestochowa":{"en":false,"de":false,"no":false}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"pl","file":"pl-PL.js","iso":"pl","name":"PL"},{"code":"en","file":"en-US.js","iso":"en-US","name":"EN"},{"code":"no","file":"no-NO.js","iso":"no-NO","name":"NO"},{"code":"de","file":"de-DE.js","iso":"de-DE","name":"DE"}],
  localeCodes: ["pl","en","no","de"],
}

export const localeMessages = {
  'pl-PL.js': () => import('../../lang/pl-PL.js' /* webpackChunkName: "lang-pl-PL.js" */),
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'no-NO.js': () => import('../../lang/no-NO.js' /* webpackChunkName: "lang-no-NO.js" */),
  'de-DE.js': () => import('../../lang/de-DE.js' /* webpackChunkName: "lang-de-DE.js" */),
}
