





































import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component<ANavbarMenuItem>({})
export default class ANavbarMenuItem extends Vue {
  @Prop({ default: null }) item!: {
    to: string;
    label: string;
  };
}
