import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=46cf0a3d&lang=pug&"
import script from "./AppFooter.vue?vue&type=script&lang=ts&"
export * from "./AppFooter.vue?vue&type=script&lang=ts&"
import style0 from "./AppFooter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppFooterPhone: require('/var/www/imoli.app/components/App/AppFooter/AppFooterPhone.vue').default,AppFooterColumns: require('/var/www/imoli.app/components/App/AppFooter/AppFooterColumns.vue').default,AppButton: require('/var/www/imoli.app/components/App/AppButton.vue').default,AppFooterContentDecoration: require('/var/www/imoli.app/components/App/AppFooter/AppFooterContentDecoration.vue').default,AppFooterDecoration: require('/var/www/imoli.app/components/App/AppFooter/AppFooterDecoration.vue').default})
